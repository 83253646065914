.searchButton {
  left: 30%;
}

.plan {
  border-bottom: 1.5px solid #f3f4f6;
}

.loadingSpinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #9e9d9d63;
  left: 0;
  top: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-box {
  left: 2.2%
}

.date-dot {
  right: 30.5px;
}

.css-lc42l8-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 10px !important;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  margin: 0px 10px !important;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 50%;

}

.Container_root__3qsfu {
  width: 70% !important;
}

.flightDate .Container_root__3qsfu {
  width: 100% !important;
}

@media screen and (max-width : 1440px) and (min-width : 1281px) {
  .Container_root__3qsfu {
    width: 90% !important;
  }
}

@media screen and (max-width : 1280px) and (min-width : 1024px) {
  .Container_root__3qsfu {
    width: 130% !important;
  }
}

@media screen and (max-width : 1023px) and (min-width : 766px) {
  .Container_root__3qsfu {
    width: 60% !important;
  }
}

@media screen and (max-width : 768px) and (min-width : 650px) {
  .Container_root__3qsfu {
    width: 80% !important;
  }
}

@media screen and (max-width : 649px) and (min-width : 550px) {
  .Container_root__3qsfu {
    width: 90% !important;
  }
}

@media screen and (max-width : 549px) and (min-width : 436px) {
  .Container_root__3qsfu {
    width: 75% !important;
  }
}

@media screen and (max-width : 435px) and (min-width : 421px) {
  .Container_root__3qsfu {
    width: 100% !important;
  }
}

@media screen and (max-width : 420px) and (min-width : 341px) {
  .Container_root__3qsfu {
    width: 90% !important;
  }
}

@media screen and (max-width : 340px) and (min-width : 321px) {
  .Container_root__3qsfu {
    width: 100% !important;
  }
}

@media screen and (max-width : 320px) {
  .Container_root__3qsfu {
    width: 105% !important;
  }
}

.Header_root__VQJBt {
  display: none !important;
}

@media screen and (max-width: 1680px) {
  .line-box {
    left: 2.5%;
    height: calc(100% - 18px)
  }

  .date-dot {
    right: 30.5px;
  }
}

@media screen and (max-width: 1440px) {
  .line-box {
    left: 3%;
    height: calc(100% - 20px)
  }

  .date-dot {
    right: 30.5px;
  }
}

@media screen and (max-width: 1024px) {
  .line-box {
    left: 4.2%;
    height: calc(100% - 40px)
  }

  .date-dot {
    right: 30.5px;
  }
}

@media screen and (max-width: 768px) {
  .line-box {
    left: 4.2%;
    height: calc(100% - 75px)
  }

  .date-dot {
    right: 30.5px;
  }
}

@media screen and (max-width: 435px) {
  .line-box {
    left: 4.8%;
    width: 99%;
    height: calc(100% - 18px)
  }

  .date-dot {
    right: 31.5px;
  }
}

@media screen and (max-width: 425px) {
  .line-box {
    left: 4.8%;
    width: 99%;
    height: calc(100% - 30px)
  }

  .date-dot {
    right: 32.5px;
  }
}

@media screen and (max-width:375px) {
  .line-box {
    width: 99%;
    left: 5.7%;
    height: calc(100% - 30px)
  }

  .date-dot {
    right: 30.5px;
  }
}

@media screen and (max-width: 320px) {
  .line-box {
    width: 99%;
    left: 5.7%;
    height: calc(100% - 30px)
  }

  .date-dot {
    right: 33.5px;
  }
}